import { StaticQuery } from "gatsby";
import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";

export default function Header() {
  const [menuIconClicked, setMenuIconClicked] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        {
          mainHeader: contentfulMainHeaderLogoButtons {
            logo {
              fluid(quality: 100, maxHeight: 800, maxWidth: 2400) {
                src
              }
            }
            button1Text
            button1Link {
              slug
            }
            button2Text
            button2Link {
              slug
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Navbar expand="md" className="p-2">
            <Navbar.Brand href="/">
              <img src={data.mainHeader.logo.fluid.src} alt="Vibild&Co" />
            </Navbar.Brand>

            {/* instead of  <Navbar.Toggle
              aria-controls="basic-navbar-nav"
            /> the following */}
            <div
              aria-controls="basic-navbar-nav"
              aria-label="Toggle navigation"
              onClick={() => setMenuIconClicked(!menuIconClicked)}
              className={`navbar-toggler${menuIconClicked ? "" : " collapsed"}`}
            >
              {menuIconClicked ? (
                <FontAwesomeIcon icon={["fas", "times"]} title="close button" />
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </div>

            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`navbar-toggler  ${menuIconClicked ? "show" : ""}`}
            >
              <Nav className="ml-auto">
                <Nav.Link
                  className="middleNav"
                  href={`/Blogs/${data.mainHeader.button1Link.slug}`}
                >
                  OM OS
                </Nav.Link>
                <Nav.Link className="middleNav" href={`/Blogs/`}>
                  BLOGS
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto">
                <Nav.Link href="mailto:john@vibildco.dk">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    title="envelope"
                  />{" "}
                  john@vibildco.dk
                </Nav.Link>
                <Nav.Link href="tel:60152021">
                  <FontAwesomeIcon
                    icon={["fas", "phone-alt"]}
                    title="phone-alt"
                  />{" "}
                  60152021
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        );
      }}
    />
  );
}
