import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";
import linkedinImg from "../images/linkedin.png";

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          contentfulFooter {
            logo {
              fluid(quality: 100, maxHeight: 800, maxWidth: 2400) {
                src
              }
              description
            }
          }
        }
      `}
      render={(data) => {
        return (
          <footer className="main-footer" id="footer">
            <Row>
              <Col xs={12} md={6}>
                <h5>Kontakt:</h5>
                <ul>
                  <li>Vibild & Co. ApS</li>

                  <a href="https://goo.gl/maps/vetp1sjeYDV8SFYF6">
                    <li>Teknikerbyen 5</li>

                    <li>2830 Virum </li>
                  </a>
                  <a href="tel:60152021">
                    <li>Tel.: 60152021</li>
                  </a>

                  <a href="mailto:john@vibildco.dk">
                    <li>E-mail: john@vibildco.dk</li>
                  </a>
                  <li>CVR.nr. 34711623</li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/john-vibild-jakobsen-300250a/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        width="70"
                        src={linkedinImg}
                        alt="John's LinkedIn profile"
                      />
                    </a>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className="footer_logo">
                  <a href="/">
                    <img
                      src={data.contentfulFooter.logo.fluid.src}
                      alt={data.contentfulFooter.description}
                    />
                  </a>
                  {/*  <p>
                    © {new Date().getFullYear()}, Built with{" "}
                    <a href="https://www.codeart.dk">Code:art</a>
                  </p> */}
                </div>
              </Col>
            </Row>

            <div className="bottom">
              <p className="bottom_positioning">
                © {new Date().getFullYear()}, Built with{" "}
                <a href="https://www.codeart.dk">Code:art</a>
              </p>
            </div>
          </footer>
        );
      }}
    />
  );
}
